import React, { useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import {
    Layout,
    SEO,
    PageTitle,
    useTranslations,
    LocaleConfig,
    usePageSections,
    Tabs,
    Tab as TabType,
    Container,
    SectionSpacer,
} from '../modules/core'

type PrivacySections =
    | 'privacyPolicy'
    | 'personalData'
    | 'terms'
    | 'transactionsLaw'

export interface PrivacyPageData {
    allMarkdownRemark: {
        edges: Array<{
            node: {
                frontmatter: {
                    section: PrivacySections
                    title: string
                    locale: LocaleConfig['locale']
                }
                html: string
            }
        }>
    }
}

export const query = graphql`
    query PrivacyQuery($locale: String) {
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    page: { eq: "privacy" }
                    locale: { eq: $locale }
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        section
                        title
                    }
                    html
                }
            }
        }
    }
`

interface PrivacyPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
    data: PrivacyPageData
}

const sectionsByOrder: Array<PrivacySections> = [
    'privacyPolicy',
    'personalData',
    'terms',
    'transactionsLaw',
]

const PrivacyPage = ({ path, pageContext, data }: PrivacyPageProps) => {
    const { translate } = useTranslations(pageContext.localeConfig.locale)
    const sections = usePageSections<PrivacySections>(data)

    const [activeTab, setActiveTab] = useState<PrivacySections>(
        sectionsByOrder[0]
    )
    const handleTabChange = useCallback(
        (id: TabType) => setActiveTab(id as PrivacySections),
        [setActiveTab]
    )

    const { content } = sections[activeTab]

    return (
        <Layout
            locale={pageContext.localeConfig}
            path={path}
            hasContactSection={false}
        >
            <SEO title={translate('page_privacy')} />
            <PageTitle>{translate('page_privacy')}</PageTitle>
            <Container>
                <TabsWrapper>
                    <Tabs current={activeTab} onChange={handleTabChange}>
                        {sectionsByOrder.map((section) => (
                            <Tab key={section} id={section}>
                                <TabTitle>{sections[section].title}</TabTitle>
                            </Tab>
                        ))}
                    </Tabs>
                </TabsWrapper>
                <TabContent
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            </Container>
            <SectionSpacer />
        </Layout>
    )
}

export default PrivacyPage

const TabsWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.lightBackground};
    margin-bottom: 65px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: calc(100% + 24px);
        margin: 0 -12px 48px -12px;
    }
`

const Tab = styled.div<{ isCurrent?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100px;
    cursor: ${({ isCurrent }) => (isCurrent ? 'default' : 'pointer')};
    background-color: ${({ isCurrent, theme }) =>
        isCurrent ? theme.colors.accent : 'rgba(255, 255, 255, 0.5)'};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        height: 66px;
        justify-content: center;
    }

    &:after {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: ${({ isCurrent }) => (isCurrent ? 'block' : 'none')};
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid ${({ theme }) => theme.colors.accent};
    }

    &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: bottom center;
        transform: scale3d(0.8, 0, 1);
        content: '';
        width: 100%;
        height: 7px;
        background-color: ${({ theme }) => theme.colors.accent};
        transition: all 300ms ease-out;
    }

    @media (hover: hover) {
        &:hover {
            &:before {
                transform: scale3d(1, 1, 1);
            }
        }
    }
`

const TabTitle = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    text-align: center;
    ${({ theme }) => theme.typography_presets.bold};
    padding: 0 4px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 12px;
    }
`

const TabContent = styled.div`
    font-size: 14px;
    line-height: 21px;

    table {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
        line-height: 24px;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            width: calc(100% + 24px);
            table-layout: fixed;
            margin: 0 -12px;
        }
    }

    tbody {
        vertical-align: top;
    }

    th {
        text-align: left;
        vertical-align: middle;
        background: ${({ theme }) => theme.colors.lightBackground};
        ${({ theme }) => theme.typography_presets.bold};

        @media ${({ theme }) => theme.breakpoints.phone_only} {
        }
    }

    td {
        border: 1px solid ${({ theme }) => theme.colors.lightBackground};
    }

    th,
    td {
        padding: 10px 24px;

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            padding: 10px 12px;
        }
    }

    ul {
        margin: 0;
        padding: 0 24px;
    }

    h3 {
        ${({ theme }) => theme.typography_presets.bold};
        margin: 0;
        font-size: 14px;
    }
`
